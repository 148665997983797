import React, { useState } from "react";
import styles from "./PopupBlack.module.css"; // Importa o CSS Module

const PopupBlack = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className={`${styles.popup} ${isVisible ? styles.show : styles.hide}`}>
                <div className={styles.popupContent}>
                    <button onClick={handleClose} className={styles.popupClose}>
                        ✕
                    </button>
                    <div>
                        <h2 className={styles.popupTitle}>Black Friday</h2>
                        <p className={styles.popupSubtitle}>50% OFF em todos os pacotes</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default PopupBlack;
