import React from "react";
import styles from './DropshippingGlobal.module.css';
import Footer from "../../../components/footer/Footer";
import DropdownTableLoja from "../../../components/dropdown-table/DropdownTableLoja";
import GoogleReviews from "../../../components/google-reviews/GoogleReviews";
import VantagensLoja from "../../../components/vantagens-loja/VantagensLoja";
import ImageVantagens from '../../../assets/images/section-vantagesdrop.webp';
import SimpleTwoColumns from "../../../components/section-simple-twocolumns/SimpleTwoColumns";
import Whatsapp from "../../../components/whatsapp/Whatsapp";
import ImageMainCta from '../../../assets/images/main-image-drop-global.webp';
import ImagePrimeiraAgencia from '../../../assets/images/home-primeira-agencia.webp';
import { Link } from "react-router-dom";
import ImageParceiroPrintful from '../../../assets/images/parceiro-printful.webp';
import ImageCall1 from '../../../assets/images/call-start.svg';
import ImageCall2 from '../../../assets/images/call-aprovacao.svg';
import ImageCall3 from '../../../assets/images/call-marketing.svg';
import RiArrowDropRightFill from 'remixicon-react/ArrowDropRightFillIcon';
import RiKey2Fill from 'remixicon-react/Key2FillIcon';
import RiToolsFill from 'remixicon-react/ToolsFillIcon';
import RiLightbulbFill from 'remixicon-react/LightbulbFillIcon';
import RiMedal2Fill from 'remixicon-react/Medal2FillIcon';
import RiWindow2Fill from 'remixicon-react/Window2FillIcon';
import RiVidiconFill from 'remixicon-react/VidiconFillIcon';
import RiShoppingBasketFill from 'remixicon-react/ShoppingBasketFillIcon';
import RiLayout3Fill from 'remixicon-react/Layout3FillIcon';
import RiFlashlightFill from 'remixicon-react/FlashlightFillIcon';
import RiReplyAllFill from 'remixicon-react/ReplyAllFillIcon';
import RiCheckDoubleFill from 'remixicon-react/CheckDoubleFillIcon';
import RiFireFill from 'remixicon-react/FireFillIcon';
import RiAddFill from 'remixicon-react/AddFillIcon';
import RiArrowDownWideLine from "remixicon-react/ArrowDropDownFillIcon";
import ImageGarantia from '../../../assets/images/garantia-drop.webp';
import Faq from 'react-faq-component';
import ImageMainCta2 from '../../../assets/images/imagenew.png';
import PackagesGlobal from "../../../components/packages-global/PackagesGlobal";

function DropshippingGlobal() {
    const whatsapp = "https://api.whatsapp.com/send?phone=5511943600303&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista%20em%20Dropshipping%20Global";

    const contentHeaderSection = {
        destaqueTitleText1: "Criamos sua ",
        titleText: "Loja de Dropshipping ",
        destaqueTitleText2: "Personalizada com Fornecedores",
        description: "Tenha acesso ao Curso mais completo para vender seu produto ou serviço na internet. Aprenda como atrair mais clientes para o seu negócio, investindo pouco e ganhando muito.",
        vantagem1: "Criação da Marca e do Logo",
        vantagem2: "Mineração e Cadastro de 60 Produtos",
        vantagem3: "02 Anúncios + Assessor de Marketing",
        vantagem4: "Mentoria + Consultoria",
        vantagem5: "Curso com Centenas de Aulas",
        vantagem6: "Suporte Dedicado 24h",
        vantagem7: "Garantia Vitalícia",
        iconInfo:"Mais de 9.000 clientes em 12 países",
        btnTextDestaque2: "Preços e Soluções",
        btnDesc: "Aproveite nossa promoção de 50% OFF",
        linkBtn: "#pacotesDoDropTitle"
    }

    const data = {
        rows: [
          {
            title: "A Loja de Dropshipping Global ou Latam é diferente da Loja de Dropshipping Tradicional?",
            content: "Sim, na Tradicional configuramos uma loja com checkout brasileiro e as descrições dos produtos são em português. Na loja Global e Latam as configurações de checkout e idioma são internacionais, assim como a descrição dos produtos em inglês ou espanhol. Desenvolvemos o layout personalizado para o idioma local."
          },
          {
            title: "Qual o idioma da loja global?",
            content: "Inglês ou espanhol, você poderá escolher."
          },
          {
            title: "Qual o prazo de desenvolvimento dos pacotes?",
            content: "Todos os desenvolvimentos são exclusivos e personalizados, por isso o prazo total de desenvolvimento pode variar de 20 à 30 dias conforme a solução contratada."
          },
          {
            title: "O domínio está incluso no pacote?",
            content: "Sim, está incluso o domínio .com e o domínio .com.br."
          },
          {
            title: "Quanto devo investir em marketing?",
            content: "Indicamos um investimento mínimo diário de R$50 para que você tenha retorno já nos primeiros dias. A definição do valor é relativo conforme o seu potencial de investimento e a velocidade de escala que deseja atingir."
          },
          {
            title: "Quanto tempo está incluso de assessoria de marketing no pacote?",
            content: "Disponibilizamos um especialista dedicado em marketing por 30 dias para gerenciar seus anúncios e campanhas. Após esse período você pode contratar a assessoria mensal de marketing ou seguir por conta própria gerenciando as campanhas que já estarão vendendo."
          },
          {
            title: "Como funciona a mineração e o cadastro dos produtos?",
            content: "Temos um departamento especializado em mineração e cadastro de produtos, que faz manualmente toda a seleção para garantir exclusividade. Escolhemos os melhores produtos para o nicho da sua loja e após a sua aprovação fazemos o cadastro em português na loja nacional e em inglês ou espanhol na loja global."
          },
          {
            title: "Como funciona a mentoria?",
            content: "Através da nossa plataforma de estudos você terá acesso a milhares de aulas, gravadas e ao vivo, que te ajudarão a vender todos os dias na internet. Além disso, você conta também com um time de mentores para te auxiliar com tudo."
          },
          {
            title: "Sou iniciante, posso comprar o pacote sem ter experiência?",
            content: "Sim, pode comprar sem medo. Nossa equipe te auxilia com tudo do zero! Desde o primeiro contato com dropshipping até as vendas."
          },
          {
            title: "Vou ter suporte 24h?",
            content: "Sim, no pacote está incluso 60 dias de suporte, após esse período você tem a opção de manter o acesso por apenas R$169 à nossa plataforma de suporte com uma equipe de plantão em horário comercial e vídeos tutoriais que te auxiliam com todas as suas dúvidas a hora que você precisar."
          },
        ]
    }

    const packageData1 = {
        packageTitle1: "Loja Dropshipping Global ou Latam Super",
        packageDescription1: "Desenvolvimento da Loja Virtual com logo profissional para venda Internacional, mineração e cadastro de 15 produtos já integrados e traduzidos com fornecedores.",
        packagePrice1: "5.997",
        packageNews1: "2.997",
        packageParcel1: "303,72",
        packageName1: " no Super",
        included1Package1: "Criação da Loja Virtual Completa",
        included2Package1: "Layout Personalizado",
        included3Package1: "Checkout para Venda Internacional",
        included4Package1: "Cadastro de 15 Produtos em Inglês ou Espanhol",
        included5Package1: "Suporte Especializado 24h",
        included6Package1: "Garantia Vitalícia",
        included7Package1: "Domínio Grátis (www.suamarca.com.br)",
        included13Package1: "Criação da Logomarca",
        included14Package1: "Anúncios (Facebook e Instagram)",
        included15Package1: "30 Dias de Assessoria de Marketing",
        packageLink1: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-super/p",
        btnName1: "Comprar"
    }

    const packageData2 = {
        packageTitle2: "Loja Dropshipping Global ou Latam Mega",
        packageDescription2: "Desenvolvimento da Loja Virtual e da Logo para venda Internacional com mineração e cadastro exclusivo de 30 produtos já integrados e traduzidos com fornecedores + 1 anúncio.",
        packagePrice2: "7.997",
        packageNews2: "3.997",
        packageParcel2: "405,06",
        packageName2: " no Mega",
        included1Package2: "Criação da Loja Virtual Completa",
        included2Package2: "Criação da Logomarca",
        included3Package2: "Checkout para Venda Internacional",
        included4Package2: "Cadastro de 30 Produtos em Inglês ou Espanhol",
        included5Package2: "Curso com Centenas de Aulas",
        included6Package2: "Suporte Especializado 24h",
        included7Package2: "Garantia Vitalícia",
        included8Package2: "Domínio Grátis (www.suamarca.com.br)",
        included9Package2: "1 Anúncio em Vídeo (Facebook e Instagram)",
        included13Package2: "30 Dias de Assessoria de Marketing",
        packageLink2: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-mega/p",
        btnName2: "Comprar"
    }

    const packageData3 = {
        packageTitle3: "Loja Dropshipping Global ou Latam Especial",
        packageDescription3: "A solução mais completa e mais vendida para venda Internacional, inclui tudo do pacote Mega + 30 produtos extras + assessoria de marketing dedidacada e 2 anúncios.",
        packagePrice3: "9.997",
        packageNews3: "4.997",
        packageParcel3: "506,40",
        packageName3: " no Especial",
        included1Package3: "Criação da Loja Virtual Completa",
        included2Package3: "Criação da Logomarca",
        included3Package3: "Checkout para Venda Internacional",
        included4Package3: "Cadastro de 60 Produtos em Inglês ou Espanhol",
        included5Package3: "Curso com Centenas de Aulas",
        included6Package3: "Suporte Especializado 24h",
        included7Package3: "Garantia Vitalícia",
        included8Package3: "Domínio Grátis (www.suamarca.com.br)",
        included9Package3: "2 Anúncios em Vídeo (Facebook e Instagram)",
        included10Package3: "30 Dias de Assessoria de Marketing",
        packageLink3: "https://dropshipping-goodds.catalog.yampi.io/pacote-loja-de-dropshipping-global-especial/p",
        btnName3: "Comprar"
    }

    const vantagensLoja = {
        vantagemTitle: "Vantagens do Dropshipping Global",
        vantagemTitle1: "Zero Risco de Taxação",
        vantagemSubtitle1: "Com fornecedores internacionais e o envio entre países fora do Brasil os produtos não tem risco de taxação.",
        vantagemTitle2: "Recebimento em Dólar",
        vantagemSubtitle2: "Fature e venda produtos no dropshipping em dólar com uma margem muito maior do que no mercado nacional.",
        vantagemTitle3: "Menos Concorrência",
        vantagemSubtitle3: "O dropshipping global é considerado atualmente uma das possibilidades mais vantajosas em comparação com o dropshipping nacional.",
        vantagemTitle4: "",
        vantagemSubtitle4: "Todas as lojas são entregues já integradas com milhares de fornecedores e uma variedade de produtos internacionais.",
        vantagemTitle5: "Milhares de Nichos",
        vantagemSubtitle5: "",
        vantagemTitle6: "",
        vantagemSubtitle6: "",
    }

    return (
        <div>
            {/* <MainCta setTop={false} mainCtaImage={ImageMainCta} data={contentHeaderSection} /> */}
            <div className={styles.containerMainCta}>
                <section className={styles.containerChild}>
                    <div className={`${styles.child1} ${styles.child}`}>
                        <div>
                            <div className={styles.gridTwoColumns}>
                                <div className={`${styles.gridTwoColumnInfo} ${styles.gridTwoColumnsChild}`}>
                                    <h1 className={styles.gridTwoColumnInfoTitle}>
                                        Criamos sua Loja de Dropshipping fora do Brasil
                                    </h1>
                                    <div className={styles.vantagens}>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Criação da Marca e do Logo</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Mineração e Cadastro de 60 Produtos</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> 02 Anúncios em Vídeo + Assessor de Marketing</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Mentoria + Consultoria</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Curso com Centenas de Aulas</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Suporte Dedicado 24h</p>
                                        <p><span className={styles.iconAdd}><RiAddFill /></span> Garantia Vitalícia</p>
                                    </div>
                                    <div className={styles.section1CTA}>
                                        <p className={`${styles.gridTwoColumnInfoCta} ${styles.destaqueText}`}>
                                            <span className={styles.iconFire}><RiFireFill /> </span>
                                            Mais de 9.000 clientes em 12 países
                                        </p>
                                        <div className={`${styles.gridTwoColumnInfoBtn} ${styles.ctaBtn}`}>
                                            <Link to="/contato" rel="noopener noreferrer">
                                                <span>
                                                    <p>Falar com Especialistas</p>
                                                </span>
                                            </Link>
                                            <p>
                                                <span><RiArrowDropRightFill />Aproveite nossa promoção de 50% OFF</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.gridTwoColumnImage} ${styles.gridTwoColumnsChild}`}>
                                    <img src={ImageMainCta} alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>

            <SimpleTwoColumns title="Especialistas em Dropshipping" descriptionOne="" descriptionDestaque="Somos a Primeira Agência de Dropshipping Nacional e Internacional do Brasil." description=" Desenvolvemos a loja personalizada e prestamos todos os serviços exclusivos para que sua loja venda todos os dias." image={ImagePrimeiraAgencia} />

            <VantagensLoja image={ImageVantagens} vantagemData={vantagensLoja} />

            <div className={styles.reviewsSection}><GoogleReviews /></div>

            <div className={styles.packagesGlobal}><PackagesGlobal showTitle={false} titleContent="" /></div>
            <DropdownTableLoja />


            <section className={styles.containerChild}>
                <div className={`${styles.child5} ${styles.child}`}>
                    <div>
                        <div>
                            <h2>
                                <span className={styles.destaqueText}>Ao contratar agora, </span>
                                você terá:
                            </h2>
                        </div>

                        <div className={styles.gridCardsInfos}>
                            <div className={styles.gridCardInfoChild}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiKey2Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Loja de Dropshipping Profissional: </h6></span>
                                    vamos criar e entregar uma Loja personalizada para você vender fora do Brasil faturando em dólar.
                                </p>
                            </div>
                            <div className={styles.gridCardInfoChild}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiToolsFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Equipe de Marketing Dedicada: </h6></span>
                                    temos pacotes com gestor de tráfego dedicado para atender sua conta e garantir que você venda nos primeiros dias.
                                </p>
                            </div>
                            <div className={styles.gridCardInfoChild}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiMedal2Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Produtos Campeões Validados: </h6></span>
                                    fazemos a seleção de cada produto e após sua aprovação cadastramos com descrições de alta conversão na sua loja.
                                </p>
                            </div>
                            <div className={styles.gridCardInfoChild}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiLightbulbFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Suporte Exclusivo 24h: </h6></span>
                                    fornecemos acompanhamento e suporte dedicado direto pela nossa plataforma exclusiva de atendimento ao cliente.
                                </p>
                            </div>
                        </div>

                        <div>
                            <h3>Você está a um clique de ter uma loja completa desenvolvida pela primeira agência especializada em Dropshipping do Brasil.</h3>
                        </div>
                                        
                    </div>
                </div>
            </section>

            <section className={styles.sectionSeparator}><RiArrowDownWideLine/></section>

            <section className={styles.containerChild}>
                <div className={`${styles.child6} ${styles.child}`}>
                    <div>
                        <div className={styles.titleSub}>
                            <h2>    
                                E você também terá
                                <span className={styles.destaqueText}> Mentoria com um time de Especialistas</span>
                            </h2>
                            <p>Além de executarmos tudo para você, também te ensinamos a vender todos os dias na internet.</p>
                        </div>

                        <div className={styles.gridCardsInfos}>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiWindow2Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Gerencie os pedidos da sua Loja</h6></span>
                                    Ensinamos o passo-a-passo para que você possa de maneira simplificada fazer a gestão dos seus pedidos e das suas vendas.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiVidiconFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Seu E-commerce do seu jeito</h6></span>
                                    Personalize e altere cores, banners, imagens e os textos da sua loja com tutoriais desenvolvidos por uma equipe de design qualificada.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiShoppingBasketFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Marketing para Facebook e Instagram</h6></span>
                                    Você vai aprender, do zero, tudo que precisa para atrair os melhores clientes para o seu e-commerce através dos anúncios patrocinados.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiLayout3Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Mineração de Produtos e Fornecedores</h6></span>
                                    Aprenda como selecionar produtos de alta conversão e escolher os fornecedores certos para sua Loja de Dropshipping.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiFlashlightFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Como utilizar IA para vender mais</h6></span>
                                    Criamos e disponibilizamos uma metodologia desenvolvida para otimizar toda sua operação utilizando Inteligência Artificial.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiReplyAllFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>Como prestar o melhor atendimento</h6></span>
                                    Através de uma equipe premiada você irá aprender a fornecer o melhor atendimento e suporte ao seu cliente no seu e-commerce.
                                </p>
                            </div>
                        </div>

                        {/* <div className={`${styles.gridTwoColumnInfoBtn} ${styles.ctaBtn}`}>
                            <a href="#pacotesDoDropTitle">
                                Preços e Soluções
                            </a>
                            <p>
                                <span className={styles.ctaBtnDesc}><RiArrowDropRightFill /> Aproveite nossa promoção de 50% OFF</span>
                            </p>
                        </div> */}

                    </div>
                </div>
            </section>

            <section className={styles.containerChild}>
                <div className={`${styles.child7} ${styles.child}`}>
                    <div>
                        <div className={styles.titleSub}>
                            <h2>Acompanhe todas as etapas do desenvolvimento com reuniões de alinhamento</h2>
                            <p>Pensamos em absolutamente tudo e compreendemos a importância de você acompanhar de perto todas as execuções.</p>
                        </div>

                        <div className={styles.gridCardsInfos}>
                            <div className={styles.gridCardInfoChild}>
                                <div>
                                    <img src={ImageCall1} alt=""/>
                                </div>
                                <div>
                                    <h3>Abertura de Grupo</h3>
                                    <p>Após a contratação do pacote abrimos seu grupo exclusivo de suporte e atendimento no WhatsApp e iniciamos o desenvolvimento.</p>
                                </div>
                            </div>
                            <div className={styles.gridCardInfoChild}>
                                <div>
                                    <img src={ImageCall2} alt=""/>
                                </div>
                                <div>
                                    <h3>Reunião de Aprovação</h3>
                                    <p>Criamos sua loja com exclusividade seguindo suas ideias e referências, e após o desenvolvimento você aprova tudo para que fique do seu jeito.</p>
                                </div>
                            </div>
                            <div className={styles.gridCardInfoChild}>
                                <div>
                                    <img src={ImageCall3} alt=""/>
                                </div>
                                <div>
                                    <h3>Reunião de Marketing</h3>
                                    <p>Com toda criação concluída nossa equipe de marketing alinha em uma reunião dedicada com você as estratégias dos anúncios patrocinados.</p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.child7Info3}>
                            <h2>E depois do desenvolvimento?</h2>

                            <p>
                                Nosso time de 
                                <span> mentores especializados </span>
                                estão sempre de plantão para tirar suas dúvidas e auxiliar no gerenciamento da sua loja para você vender todos os dias na internet.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <section className={`${styles.containerChild} ${styles.sectionBg}`}>
                <div className={`${styles.child8} ${styles.child}`}>
                    <div>
                        <div className={styles.child8Title}>
                            <h2>Todos os Bônus que você recebe ao adquirir nosso pacote:</h2>
                        </div>

                        <div className={styles.gridCardsInfos}>
                            <div className={styles.gridCardInfoChild}>
                                <img src={ImageParceiroPrintful} alt="" />
                            </div>

                            <div className={styles.gridCardInfoChild}>
                                <h2>Integração da Printful para venda de produtos personalizados sob demanda sem estoque</h2>

                                <p>A Printful é a maior empresa de Print-on-Demand do mundo. Ela disponibiliza mais de 340 produtos para serem personalizados com a sua marca.</p>
                            </div>
                        </div>

                        <div className={styles.gridCardsMiniInfos}>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Tema Extra de Alta Conversão</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Curso de Técnicas e Estratégias de Vendas</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Acesso a Fornecedores Exclusivos</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Consultoria de Contabilidade</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Condições Especiais em Apps e Plataformas</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Receba um Funil de Vendas Validado</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.title}>
                            <h2>
                                <span>Bônus EXCLUSIVOS </span>
                                para Venda Global
                            </h2>
                        </div>

                        <div className={`${styles.gridCardsMiniInfos} ${styles.miniInfoTwoColumns}`}>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Metodologia para Mineração Global</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridCardMiniInfoChild}>
                                <div>
                                    <div>
                                        <span className={styles.checkIconDrop}><RiCheckDoubleFill /></span>
                                    </div>
                                    <div>
                                        <p>Estudo Analítico dos Principais Países</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className={styles.reviewsSection}><GoogleReviews /></div>

            <div className={styles.packagesGlobal}><PackagesGlobal showTitle={false} titleContent="" /></div>

            <section className={styles.containerChild}>
                <div className={`${styles.child10} ${styles.child}`}>
                    <div>
                        
                        {/* <DropdownTableLoja /> */}

                        <div className={styles.gridTwoColumns}>
                            <div className={styles.gridTwoColumnsChild}>
                                <div>
                                    <img src={ImageGarantia} alt="" />
                                </div>
                            </div>

                            <div className={styles.gridTwoColumnsChild}>
                                <div>
                                    <h3>Garantia Vitalícia</h3>
                                    <p>Você recebe nossa Garantia Técnica para sempre, assegurando que a solução adquirida funcionará conforme especificações técnicas prometidas. Esta garantia é válida para clientes que possuírem nosso suporte mensal.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


            <section className={`${styles.containerChild} ${styles.faqSection}`}>
                <div className={`${styles.child11} ${styles.child}`}>
                    <div>
                        <div className={styles.title}>
                            <h2>Ainda com dúvida?</h2>
                            <p>Abaixo você encontra as perguntas mais comuns sobre os pacotes</p>
                        </div>

                        <div className={styles.faq}>
                            <Faq data={data}/>
                        </div>
                    </div>
                </div>
            </section>

            <Footer mostrarSecao={false} />
            <Whatsapp whatsappMessage={whatsapp} />
        </div>
    )
}

export default DropshippingGlobal;