import React from 'react';
import styles from './BlackFriday.module.css';
import GoogleReviews from '../../components/google-reviews/GoogleReviews';
import logo from '../../assets/images/logo.webp';
import PaymentIcons from '../../assets/images/payment-icons.webp';
import RiArrowDropRightFill from 'remixicon-react/ArrowDropRightFillIcon';
import RiKey2Fill from 'remixicon-react/Key2FillIcon';
import RiToolsFill from 'remixicon-react/ToolsFillIcon';
import RiLightbulbFill from 'remixicon-react/LightbulbFillIcon';
import RiMedal2Fill from 'remixicon-react/Medal2FillIcon';
import RiWindow2Fill from 'remixicon-react/Window2FillIcon';
import RiVidiconFill from 'remixicon-react/VidiconFillIcon';
import RiShoppingBasketFill from 'remixicon-react/ShoppingBasketFillIcon';
import RiLayout3Fill from 'remixicon-react/Layout3FillIcon';
import RiFlashlightFill from 'remixicon-react/FlashlightFillIcon';
import RiReplyAllFill from 'remixicon-react/ReplyAllFillIcon';
import RiCheckFill from 'remixicon-react/CheckFillIcon';
import RiArrowRightSLine from 'remixicon-react/ArrowRightSLineIcon';
import RiAddFill from 'remixicon-react/AddFillIcon';
import Separator from '../../assets/images/separator-lp.svg';
import RiFireFill from 'remixicon-react/FireFillIcon';
import Whatsapp from '../../components/whatsapp/Whatsapp';
import 'swiper/css';
import 'swiper/css/pagination';
import ImageBandeiraBr from '../../assets/images/br-lp-global.svg';
import ImageBandeiraUsa from '../../assets/images/usa-lp-global.svg';
import ImagePrincipal from '../../assets/images/black-topo-image.webp';
import { Link } from 'react-router-dom';


function BlackFriday() {
    const whatsapp = "https://api.whatsapp.com/send?phone=5511943600303&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista%20em%20Dropshipping";

    return (
        <div className={`${styles.container} ${styles.blackContainer}`} id="lp-vendas">

            <section className={`${styles.containerChild} ${styles.containerMainCta}`}>
                <div className={`${styles.child1} ${styles.child}`}>
                    <div>
                        <div className={styles.logo}>
                            <Link to="/">
                                <img src={logo} alt=""/>
                            </Link>
                        </div>
                        <div className={styles.gridTwoColumns}>
                            <div className={`${styles.gridTwoColumnInfo} ${styles.gridTwoColumnsChild}`}>
                                <h1 className={styles.gridTwoColumnInfoTitle}>
                                    VENDA MUITO NA <span className={styles.destaqueTopoBlack}>BLACKFRIDAY</span>
                                </h1>
                                <div className={styles.lojaUSAeBR}>
                                    <h5>
                                        Criamos um <span>MEGA PACOTE</span> com tudo o que sua LOJA precisa para vender em NOVEMBRO no mês da BlackFriday
                                    </h5>
                                    <h5>
                                        A HORA É AGORA: 
                                    </h5>
                                </div>
                                <div className={styles.lojaUSAeBRBenefits}>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        ⁠Mineração de 10 produtos focados na black friday
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        Call de alinhamento com especialistas de produtos 
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        ⁠10 posts pro insta + bio e 03 destaques 
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        0⁠2 Banners para loja
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        02 Campanhas Meta
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        Call de alinhamento de Marketing com 2 gestores (Incluso análise de performance geral)
                                    </p>
                                    <p>
                                        <span><RiAddFill /> </span>
                                        2 Criativos em vídeo 
                                    </p>
                                </div>
                                <h5 className={styles.gridTwoColumnInfoCta}>
                                    <span><RiFireFill className={styles.iconFireHeader} /> </span>
                                    O PACOTE MAIS COMPLETO E MAIS BARATO QUE JÁ OFERECEMOS
                                </h5>
                                <div className={`${styles.gridTwoColumnInfoBtn} ${styles.ctaBtn} ${styles.ctaBtnChild1}`}>
                                    <a href="#blackSegundoPacote">
                                        Pacote Completo
                                        <span>
                                            <h3> R$1.497 </h3>
                                        </span>
                                        <span>
                                            <h3>ou 12x de R$151</h3>
                                        </span>
                                    </a>
                                    <p>
                                        <span><RiArrowDropRightFill /> Liberamos apenas 100 pacotes para venda com esse preço até o dia 31/10</span>
                                    </p>
                                </div>
                            </div>

                            <div className={`${styles.gridTwoColumnImage} ${styles.gridTwoColumnsChild}`}>
                                <img src={ImagePrincipal} alt=""/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className={styles.containerChild}>
                <div className={`${styles.child6} ${styles.child}`}>
                    <div>
                        <div className={styles.titleSub}>
                            <h2>    
                                Ao fechar agora você terá:
                            </h2>
                        </div>

                        <div className={styles.gridCardsInfos}>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiWindow2Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>10 produtos exclusivos para a Blackfriday</h6></span>
                                    Seleção de 10 produtos + cadastro + uma call de alinhamento e escolha dos produtos
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiVidiconFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>10 posts no Instagram</h6></span>
                                    Criação e publicação de 10 postagens estratégicas no mês de novembro com foco em vender na blackfriday.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiShoppingBasketFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>03 destaques de Blackfriday</h6></span>
                                    Capa dos destaques temática de blackfriday.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiLayout3Fill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>02 banners personalizados</h6></span>
                                    Desenvolvimento das artes personalizadas para destaque em produtos.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderLeft}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiFlashlightFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>02 Campanhas no Meta</h6></span>
                                    Configuração e publicação das campanhas no Instagram e Facebook com acompanhamento dedicado com dois gestores + call de alinhamento e definições.
                                </p>
                            </div>
                            <div className={`${styles.gridCardInfoChild} ${styles.borderRight}`}>
                                <p>
                                    <span className={`${styles.destaqueText} ${styles.iconStyle}`}><RiReplyAllFill /></span>
                                </p>
                                <p>
                                    <span className={styles.destaqueText}><h6>02 criativos em vídeos</h6></span>
                                    Desenvolvimento dos anúncios com edição em vídeo profissional e atrativo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.containerChild}>
                <div className={`${styles.child9} ${styles.child}`}>
                    <div>
                        <div className={`${styles.gridTwoColumns} ${styles.lastChild}`}>
                            <div className={styles.gridTwoColumnsChild}>
                                <div className={styles.logoInscrever}>
                                    <span>30 dias de serviços com um time dedicado</span>
                                    <h1>A SUA <span>BLACKFRIDAY</span> NUNCA MAIS SERÁ A MESMA </h1>
                                </div>

                                <div className={styles.ctaOther}>
                                    <p>
                                        <span>Pacote Completo</span>
                                        <span className={`${styles.ctaDestaque} ${styles.destaqueText}`}>
                                            <h3>R$1.497</h3>
                                            <span>ou 12x de <span>R$151</span></span>
                                        </span>
                                    </p>
                                    <p>
                                        Restam apenas 39 pacotes para venda com esse preço até o dia 06/11
                                    </p>
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.titulosEntrePacotes} id='blackSegundoPacote'>
                    <h4>Você achou que estava bom? Calma!</h4>
                    <h2>Ahhhh meu Deus é muito barato e eu não posso perder! MAS EU QUERIA MAIS!</h2>
                    <h3>Então aqui vai…</h3>
                </div>

                <div className={`${styles.child9} ${styles.child} ${styles.child9SegundoFilho}`}>
                    <div>
                        <div className={`${styles.gridTwoColumns} ${styles.lastChild}`}>
                            <div className={styles.gridTwoColumnsChild}>
                                <div className={styles.logoInscrever}>
                                    <span>Não pensamos somente em <strong>NOVEMBRO</strong>, pensamos também em <strong>DEZEMBRO</strong>! <span>E DOBRAMOS O PACOTE:</span></span>
                                    <h1>Tudo o que vamos fazer em NOVEMBRO para a <span>BLACKFRIDAY</span>, vamos fazer também em DEZEMBRO para o <span>NATAL!!!</span></h1>
                                </div>

                                <div className={styles.ctaOther}>
                                    <p>
                                        <span>Pacote Completo</span>
                                        <span className={`${styles.ctaDestaque} ${styles.destaqueText}`}>
                                            <h3>MASTER R$2.497</h3>
                                            <span>ou 12x de <span>R$253</span></span>
                                        </span>
                                    </p>
                                    <p className={styles.avisoSegundoPacote}>
                                        Liberamos apenas 100 pacotes para venda com esse preço até o dia 31/10
                                        <span>(NÃO IREMOS VENDER APÓS O DIA 31 pq se não, não vai dar tempo de vender na BLACKFRIDAY)</span>
                                    </p>
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.btnPacotesBlack}>
                    <div>
                        <a className={styles.btnBlackFriday} href="https://dropshipping-goodds.catalog.yampi.io/black-novembro-megapromocao/p">
                            Pacote <strong>NOVEMBRO</strong>
                            <span><strong>R$1.497</strong> ou 12x de <strong>R$151</strong></span>
                        </a>
                    </div>
                    <div>
                        <a className={styles.btnBlackFriday} href="https://dropshipping-goodds.catalog.yampi.io/black-novembro-e-dezembro-megapromocao/p">
                            Pacote <strong>NOVEMBRO E DEZEMBRO</strong>
                            <span><strong>R$2.497</strong> ou 12x de <strong>R$253</strong></span>
                        </a>
                    </div>
                </div>
            </section>

            <section className={`${styles.containerChild} ${styles.footerBg}`}>
                <div className={`${styles.child12} ${styles.child}`}>
                    <div className={styles.footerGrid}>
                        <div>
                            <img src={logo} alt="" />
                        </div>

                        <p>Somos a Primeira Agência especializada em Dropshipping do Brasil e já entregamos mais de 9.000 lojas em 12 países.</p>

                        <div className={styles.copy}>
                            <p>© 2024 Goodds - Todos os direitos reservados</p>
                        </div>  
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlackFriday;