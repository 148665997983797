import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './FooterCta.module.css';
import { Link } from 'react-router-dom';

function FooterCta() {

    let linkCtaWhatsapp = "https://api.whatsapp.com/send?phone=5511943600303&text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista%20em%20dropshipping";

    return (
        <section className={styles.footerCtaContainer}>
            <div className={styles.footerCtaContent}>
                <h1>Comece a vender com a Goodds</h1>
                <p>Soluções práticas e inovadoras, feitas para te ajudar a vender na internet.</p>
                <Link className={`${styles.ctaButtonDesktop} ${styles.ctaButtonDesktopLink}`} to="/contato">
                        <span>Falar com um especialista</span>
                </Link>
            </div>
        </section>
    );
}

export default FooterCta;
